<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row class="mt-1 ofRez">
        <b-col lg="8" md="6" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Rezervasyona Kapalı Günler</h4>
                </b-col>
              </div>
            </b-row>
            <hr>
            <b-table
                class="table-striped mt-2"
                empty-text="Veri Bulunamadı"
                striped
                hover
                :items="ofRezList"
                :fields="fields"
            >
              <template #cell(actions)="row">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="bgDanger"
                    class="btn-icon rounded-circle mr-1"
                    size="sm"
                    @click="ofRezRemove(row.item)"
                    :style="{backgroundColor:$store.getters.bgDanger}"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </template>
            </b-table>
          </b-card-actions>
        </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Yeni Kayıt</h4>
                </b-col>
              </div>
            </b-row>
            <hr>
            <b-form-group
                label="Başlangıç Tarihi"
                label-for="basicInput"
                class="mt-1"
            >
              <flat-pickr
                  v-model="baslangic"
                  class="form-control"
                  placeholder="Tarih Seçiniz"
              />
            </b-form-group>
            <b-form-group
                label="Bitis Tarihi"
                label-for="basicInput"
                class="mt-1"
            >
              <flat-pickr
                  v-model="bitis"
                  class="form-control"
                  placeholder="Tarih Seçiniz"
              />
            </b-form-group>
            <b-form-group
                label="Mesajınız"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-textarea
                  v-model="mesaj"
                  rows="3"
              />
            </b-form-group>
            <b-row>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:this.$store.getters.bgSuccess}"
                  @click="ofRezSave"
              >
                Kaydet
              </b-button>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BFormTextarea,
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile, BSpinner,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr

flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  components: {
    BCardActions,
    BFormTextarea,
    flatPickr,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    BFormFile,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      baslangic: null,
      bitis: null,
      mesaj: null,
      fields: [
        {
          key: 'baslangic',
          label: 'BAŞLANGIÇ TARİHİ',
          sortable: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'bitis',
          label: 'BİTİŞ TARİHİ',
          sortable: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'mesaj',
          label: 'MESAJ',
          sortable: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
      ],
    }
  },
  computed: {
    ofRezList() {
      return this.$store.getters.ofRezReturn.liste
    },
  },
  created() {
    this.$store.dispatch('offlineRez')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearOfflineRez')
  },
  methods: {
    ofRezRemove(val) {
      this.$store
          .dispatch('ofRezRemove', { id: val.cizelgeID })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    ofRezSave() {
      this.$store
          .dispatch('ofRezSave', {
            baslangic: this.baslangic,
            bitis: this.bitis,
            mesaj: this.mesaj
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            (this.baslangic = null);
            (this.bitis = null),
                (this.mesaj = '')
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.ofRez .table th,
.ofRez .table td {
  padding: 0.1rem !important;
}
</style>
<style scoped>
.row {
  justify-content: end !important;
}

[dir] .table th,
[dir] .table td {
  padding: 0.3rem 1rem !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.card-body {
  padding: 10px 30px;
}
</style>
